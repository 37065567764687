::ng-deep .dropdown-menu {
  min-width: 178px;
}

typeahead-container {
  visibility: visible !important;

  .dropdown-item.active,
  .dropdown-item:active {
    background: map-get($colors, primary);
  }
}
