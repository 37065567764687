app-navbar {
  .navbar {
    .nav-link.fw-bold {
      letter-spacing: 0.08rem;
    }

    .v-nav-item {
      @include transition;

      &:hover,
      &.active {
        a {
          // color: white;
          color: #000;

          i {
            // color: white;
            color: map-get($colors, primary);
          }
        }
      }

      // TO DO : DELETE WITH NEW TEMPLATE
      &.active {
        background: var(--bs-light);
      }
    }
  }

  #sidebar {
    &.navbar {
      position: absolute;
      font-size: 0.9rem;
    }
  }
}
