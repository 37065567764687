.v-table {
  background: white;
  margin: 0;

  & > :not(:first-child) {
    border-top: 0;
  }

  tr {
    position: relative;

    &:hover {
      .v-table-actions {
        display: block;
      }
    }

    .v-table-clipboard {
      .v-table-clipboard--action {
        opacity: 0;
      }

      &:hover {
        .v-table-clipboard--action {
          opacity: 1;
        }
      }
    }
  }

  &-actions {
    @include transition;

    position: absolute;
    right: 0.95rem;
    z-index: 9;

    height: 60px;
    width: 105px;

    background: rgb(255, 255, 255);
    background: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );

    .btn-toolbar {
      margin-top: -0.3rem;
    }

    display: none;
  }

  td.avatar {
    width: 50px;
  }

  &.table-striped > tbody > tr:nth-of-type(odd) {
    & > * {
      --bs-table-accent-bg: rgba(0, 0, 0, 0.01) !important;
    }

    &:hover {
      @include transition;
      background-color: rgba(map-get($colors, primary), 0.05);
    }
  }

  &-hover tbody tr:hover {
    @include transition;

    background-color: rgba(map-get($colors, primary), 0.05);
  }

  th,
  td {
    border: 0 !important;
    padding: 1.3rem 2.2rem 1.3rem 1.3rem;
    white-space: nowrap;
  }

  thead th {
    @include transition;

    font-weight: normal;
    color: rgba(0, 0, 0, 0.4) !important;
    cursor: pointer;

    &.active,
    &:hover {
      color: rgba(0, 0, 0, 1) !important;
    }
  }

  tbody tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03) !important;
    cursor: pointer;

    td:not(.v-text--highlight) {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}
