//- Modal
.modal {
  // TO DO DELETE THAT WITH NEW TEMPLATE
  &-content,
  &-header {
    border: none;
  }

  &-body,
  &-footer {
    padding: 2rem;
  }

  &-header {
    padding: 2rem 2rem 0 2rem;
  }

  .webhook-wrapper {
    text-align: left;
    margin-top: 15px;
  }

  #webhook {
    text-align: left;
    color: black;
    border: 1px solid #d6e9ff;
    border-radius: 5px;
    background-color: #e6f1ff;
    padding-top: 15px;
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 12px;
  }
}
