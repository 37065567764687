app-entries {
  .entry-card {
    a {
      color: black;
    }

    &--border {
      border-bottom: 1px solid var(--bs-light);
    }

    &--last {
      padding-bottom: 0;
    }
  }
}
