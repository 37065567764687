ng-sidebar-container {
  min-height: 100vh !important;

  .ng-sidebar {
    min-height: 100vh;
    width: 70%;
    max-width: 950px;
    background: var(--bs-light);
    box-shadow: 0 0 10px rgba(map-get($colors, greylight), 0.15);
    z-index: 3 !important;

    &__content {
      overflow-x: hidden !important;
    }

    &__backdrop {
      z-index: 3 !important;
    }
  }
}
