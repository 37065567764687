.modals-questions {
  &-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hover-grab {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}

label[for="is_template"].small.form-check-label.text-muted {
  font-weight: bold !important;
  margin-bottom: 15px;
}
