.StripeElement {
  padding-top: 0.5rem !important;
}

// TO DO MIGRATE ALL STRIPE ELEMENT JS TO NGX CARD
#stripeCard.form-control {
  .StripeElement {
    padding-top: 0rem !important;
  }
}
