app-topbar {
  .topbar {
    // background: white;
    border-bottom: 1px solid map-get($colors, light);

    .list-group-item,
    .nav-item {
      @include transition;

      cursor: pointer;
      color: map-get($colors, greylight);

      &-active,
      &:hover .nav-link i {
        color: map-get($colors, primary);
      }
    }

    .company-picture {
      background-position: center center;
      background-size: 90% auto;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
    }

    .list-group-item:hover {
      color: map-get($colors, primary);
    }
  }
}
