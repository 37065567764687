@mixin transition {
  transition: all 0.3s ease-in-out;
}

* {
  outline: none !important;
}

html,
body {
  background-color: var(--bs-light);
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, system-ui, SFUI, HelveticaNeue, Helvetica, Arial,
    sans-serif;
}

a {
  @include transition;

  color: map-get($colors, greylight);
  text-decoration: none;

  &:hover {
    color: map-get($colors, primary);
  }
}

.form-floating:not(.ng-invalid) {
  color: darken(map-get($colors, greylight), 20);
}

.nav-link {
  color: map-get($colors, greylight);
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

// Google search place
.pac-container {
  z-index: 1200 !important;

  &:after {
    /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

    background-image: none !important;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

.form-control {
  @include transition;

  &:focus {
    background-color: rgba(map-get($colors, primary), 0.1);
    border-color: map-get($colors, primary);
    box-shadow: none;
  }

  &.ng-invalid.ng-touched {
    color: map-get($colors, danger);
    background-color: rgba(map-get($colors, danger), 0.1);
    border-color: map-get($colors, danger);
  }
}

.pages {
  &-full {
    position: relative;
    min-height: 100vh;
  }

  @media (max-width: 767.98px) {
    &-mobile {
      margin-bottom: 3rem;
    }
  }
}

.profile-picture {
  display: inline-block;
  background-position: center center;
  background-size: 90% auto;
  background-repeat: no-repeat;
  width: 150px;
  height: 150px;
}

.picture-upload {
  width: 150px;
  height: 150px;

  &-image-container {
    position: relative;
  }

  &:hover .picture-upload--overlay {
    opacity: 0.7;
  }

  &--overlay {
    @include transition;

    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    color: #f1f1f1;
    width: 100%;
    opacity: 0;
    color: white;
    font-size: 12px;
    padding: 1rem;
    text-align: center;
  }

  #file-input {
    display: none;
  }
}

.required:after {
  content: ' *';
  color: map-get($colors, danger);
  font-size: 80%;
  font-weight: 400;
}

.v {
  &-textarea {
    height: 100px !important;
  }

  &-bg {
    &-primary {
      background-color: map-get($colors, primary);
    }
  }

  &-popover {
    &-apps {
      width: 200px;

      .list-group-flush {
        max-height: 150px;
        overflow-y: auto;
      }

      &-app {
        cursor: pointer;
        width: 98px;
        height: 98px;

        &:hover {
          text-decoration: none;
          background-color: map-get($colors, light);
        }
      }

      .popover-body {
        padding: 0 !important;
      }
    }
  }

  &-header {
    &--sticky {
      margin: 0 -30px;
      padding: 1rem;
      background-color: white;
      box-shadow: 0px 15px 10px -15px rgba(map-get($colors, greylight), 0.15);
    }
  }

  &-avatar {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-size: cover;
    background-position: center;
    vertical-align: middle;

    &--small {
      width: 30px;
      height: 30px;
    }

    &--large {
      width: 80px;
      height: 80px;
    }
  }

  &-alert {
    &-primary {
      color: darken(map-get($colors, primary), 10);
      background-color: lighten(map-get($colors, primary), 40);

      a {
        color: map-get($colors, primary);
      }
    }
  }

  &--opacity {
    &-50 {
      opacity: 0.5 !important;
    }

    &-70 {
      opacity: 0.7 !important;
    }
  }

  &-text {
    &--highlight {
      font-weight: 700;
      color: map-get($colors, primary) !important;
    }

    &-success {
      color: map-get($colors, success);
    }

    &-primary {
      color: map-get($colors, primary);
    }

    &-warning {
      color: map-get($colors, warning);
    }

    &-danger {
      color: map-get($colors, danger);
    }

    &-purple {
      color: #9b59b6;
    }
  }

  &-toastr {
    box-shadow: 0 0 10px rgba(map-get($colors, greylight), 0.15) !important;
    padding: 1rem !important;
    margin: 0 1rem 1rem !important;
    background-image: none;

    &.toast-success {
      background-color: map-get($colors, success) !important;
      background-image: none !important;
    }

    &.toast-danger {
      background-color: map-get($colors, danger) !important;
      background-image: none !important;
    }
  }

  &-badge {
    color: white;

    &-primary {
      background: map-get($colors, primary);
    }

    &-success {
      background: map-get($colors, success);
    }

    &-warning {
      background: map-get($colors, warning);
    }
  }

  &-btn {
    @include transition;

    &-link {
      color: map-get($colors, greylight);
      text-decoration: none;

      &:hover {
        color: map-get($colors, primary);
      }
    }

    &-group > .btn {
      box-sizing: border-box;

      :last-child:hover {
        border: 0 !important;
      }

      &:first-child {
        border-right: 1px solid darken(map-get($colors, primary), 10);
      }
    }

    &-outline {
      &-primary {
        color: map-get($colors, primary);
        border-color: map-get($colors, primary);

        &:hover,
        &:focus,
        &:active,
        &:disabled,
        &:focus:active {
          color: white !important;
          background-color: map-get($colors, primary);
          border-color: map-get($colors, primary);
        }
      }
    }

    &-primary {
      background: map-get($colors, primary);
      color: white !important;

      &:not(.disabled):hover {
        background: #000000;

        &,
        & > i {
          color: white !important;
        }
      }

      &:focus,
      &:active,
      &:disabled,
      &:focus:active,
      &:visited {
        background: darken(map-get($colors, primary), 1%);

        &,
        & > i {
          color: white !important;
        }
      }
    }

    &-dark {
      background: #000000;
      color: white !important;

      &:not(.disabled):hover {
        background: #000000;

        &,
        & > i {
          color: white !important;
        }
      }

      &:focus,
      &:active,
      &:disabled,
      &:focus:active,
      &:visited {
        background: darken(map-get($colors, dark), 1%);

        &,
        & > i {
          color: white !important;
        }
      }
    }

    &-danger {
      background: map-get($colors, danger);
      color: white !important;
      &--hover {
        &:hover,
        &:focus,
        &:active,
        &:disabled,
        &:focus:active,
        &:visited {
          background: map-get($colors, danger);
          color: white !important;

          i,
          .v-text-success {
            color: white !important;
          }
        }
      }
    }

    &-success {
      background: map-get($colors, success);
      color: white !important;
    }

    &-secondary {
      background: white;
      color: map-get($colors, dark) !important;

      &--hover {
        &:hover,
        &:focus,
        &:active,
        &:disabled,
        &:focus:active,
        &:visited {
          background: map-get($colors, primary) !important;
          color: white !important;

          i,
          .v-text-success {
            color: white !important;
          }
        }
      }
    }

    &-light {
      // border: 1px solid map-get($colors, light);
      background: var(--bs-light);
    }

    &-warning {
      background: map-get($colors, warning);
      color: white !important;
    }

    &-sm {
      &-block {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      &-lg {
        @include media-breakpoint-down(sm) {
          padding: 0.5rem 1rem;
          font-size: 1.25rem;
          line-height: 1.5;
        }
      }
    }
  }

  &-input {
    height: 2rem;
    border-radius: 1rem !important;
    padding-left: 1rem !important;
  }

  &-card,
  &-table {
    box-shadow: 0 0 10px rgba(map-get($colors, greylight), 0.15);
    border-radius: 0.3rem;
    border: 0;
  }

  &-square-card {
    display: inline-flex;
    padding: 0;
    justify-content: center;
    width: 12rem;
    height: 12rem;
  }

  &-card {
    &-charts {
      height: 300px;

      .card-body {
        padding: 0 !important;
      }

      &-content {
        height: 300px;
      }
    }

    @media (hover: hover) {
      &--highlightable {
        opacity: 0.7;
      }

      &--highlighted {
        transform: scale(1.01);
        opacity: 1;
      }
    }

    &--sticky {
      margin: 0 -30px;
      padding: 0;
      background-color: white;
      border-radius: 0;
      box-shadow: 0px 15px 10px -15px rgba(map-get($colors, greylight), 0.15);
    }

    &--highlight {
      background-color: map-get($colors, primary);
      color: white;
    }

    &--highlightable {
      @include transition;
      cursor: pointer;
      scroll-margin-top: 20px;
    }

    .card-body,
    .card-footer {
      padding: 2rem;
    }

    &--noMinHeight {
      min-height: 0;
    }
  }

  &--text-ellipsis {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  &--text-break {
    word-break: break-word;
    white-space: normal !important;
  }

  &-dropzone {
    background: var(--bs-light);

    &-content {
      color: map-get($colors, primary);
      font-size: 0.8rem;
    }
  }
}

.navbar {
  .nav-link.disable {
    opacity: 0.25;
  }
}

tabset {
  .nav-pills {
    .nav-link {
      &:hover {
        color: map-get($colors, primary);
      }

      &.active {
        background-color: rgba(map-get($colors, primary), 0.15);
        color: map-get($colors, primary);
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    .nav-item {
      margin-right: 0.5rem;
    }
  }
}

.dropdown {
  &-item {
    cursor: pointer;

    &:active {
      background-color: map-get($colors, primary);
    }
  }
}

.page-item {
  .page-link {
    color: map-get($colors, primary);
    border: none;
  }

  &.active .page-link {
    border-color: map-get($colors, primary);
    background: map-get($colors, primary);
  }
}

.pointer {
  cursor: pointer;
}

.fadeIn {
  @include fadeIn();
}

.shake {
  @include shake();
}

.welcome {
  min-height: calc(100vh - 81px);
}
